import { Navbar } from "../../../components/Navbar";
import styles from "./ScannerResults.module.css";
import { useBasicInformation } from "../../../hooks/useBasicInformation";
import { Link } from "react-router-dom";

export const ScannerResults = () => {
  const completeBasicInformation = useBasicInformation();
  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }
  const [email, basicInformation] = completeBasicInformation;

  const currentDate = new Date().toDateString();

  return (
    <section className={styles.scannerResultsContainer}>
      <div className={styles.title}>
        <h2>Hi, {basicInformation.firstName}</h2>
        <p>
          Last update <span>{currentDate}</span>
        </p>
      </div>
      <div className={styles.buttonsMainContainer}>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart"></i>
            </p>
            <p>Heart rate</p>
            <p>80 BPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-heart-pulse"></i>
            </p>
            <p>HRV</p>
            <p>60 MS</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-lungs"></i>
            </p>
            <p>Respiration rate</p>
            <p>15 RPM</p>
          </div>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-brain"></i>
            </p>
            <p>Stress level</p>
            <p>Low</p>
          </div>
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonContainer}>
            <p>
              <i className="fa-solid fa-droplet"></i>
            </p>
            <p>Blood pressure</p>
            <p>136/97</p>
          </div>
        </div>
      </div>
      <Link className={styles.navigationButton} to="/scanner/charts">
        Charts
      </Link>
      <Navbar />
    </section>
  );
};
