import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ScannerVideo.module.css";
import { Wave } from "../../../components/Wave";

export const ScannerVideo = () => {
  const [isPlaying, setIsPlaying] = useState<Boolean>(false);
  const videos = useRef<HTMLVideoElement>(null);
  const navigate = useNavigate();

  const navigateResults = () => navigate("/scanner/results");

  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    let mediaStream: MediaStream;
    if (!videos.current) return;
    const stream = async () => {
      if (!videos.current) return;
        videos.current.style.objectFit = 'cover';

      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: { 
          facingMode: "user",
          height: { ideal: screenHeight },
        },
        audio: false,
      });

      const track = mediaStream.getVideoTracks()[0];
      videos.current.srcObject = mediaStream;

      videos.current.controls = true;
      setIsPlaying(true)
      if (!videos.current.srcObject) {
        videos.current.srcObject = mediaStream;
      }
      videos.current.controls = false;
    };

    stream().catch(console.error);
    setTimeout(() => {
      mediaStream.getVideoTracks()[0].stop();
      navigateResults();
    }, 10e3);
  });

  return (
    <section className={styles.scannerContainer}>
      <div className={styles.faceScannerContainer}>
        <span className={styles.scannerOval}></span>
        <video
          ref={videos}
          className={styles.videoTag}
          autoPlay
          playsInline
        ></video>
        <div className={styles.instructions}>
          <div className={styles.lineContainer}>{isPlaying && <Wave />}</div>
          <p>Place your face inside the outlined oval</p>
        </div>
      </div>
    </section>
  );
};
