import styles from "./goals.module.css";
import Logo from "../../assets/logo-color.png";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useGoals } from "../../hooks/useGoals";
import { z } from "zod";
import { storeGoals } from "../../lib/storeFunctions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const ZGoals = z.object({
  calories: z.number().min(1),
  steps: z.number().min(1),
});

export const Goals = () => {
  const navigate = useNavigate();
  const userGoals = useGoals();

  const [calories, setCalories] = useState<number>(0);
  const [steps, setSteps] = useState<number>(0);
  const [error, setError] = useState<string>("");

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name !== "calories" && name !== "steps") {
      console.log(`Unexpected name: ${name}`);
      return;
    }

    if (name === "calories") {
      setCalories(Number(value));
      return;
    }
    if (name === "steps") {
      setSteps(Number(value));
      return;
    }
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    const zGoals = ZGoals.safeParse({ calories, steps });
    if (!zGoals.success) {
      setError("You must enter a valid value");
      return;
    }

    storeGoals(zGoals.data);
    navigate("/home");
  };

  return (
    <section className={styles.goalsContainer}>
      <div className={styles.backLinkContainer}>
        <Link to="/home" className={styles.backLink}>
          <span>
            <i className="fa-duotone fa-solid fa-chevron-left"></i>
          </span>
        </Link>
      </div>
      <figure className={styles.goalsLogo}>
        <img src={Logo} alt="HealthLink360 logo" />
      </figure>
      <form
        onSubmit={handleSubmit}
        id="goals-form"
        className={styles.goalsForm}
      >
        <div className={styles.goalsParagraphs}>
          <h1>Tell us about your goals</h1>
          <p>You can update your goals every time you want</p>
          {userGoals && (
            <>
              <p>Your current goals are:</p>
              {userGoals.calories > 0 && (
                <p>Calories goal: {userGoals.calories}</p>
              )}
              {userGoals?.steps > 0 && <p>Steps goal: {userGoals.steps}</p>}
              {userGoals.calories === 0 && userGoals.steps === 0 && (
                <p>Add your goals now!</p>
              )}
            </>
          )}
        </div>
        <div className={styles.inputGoalContainer}>
          <label htmlFor="calories-goal">Calories goal</label>
          <p>* Your daily calories goal</p>
          <input
            type="number"
            name="calories"
            placeholder="Enter your calories goal"
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputGoalContainer}>
          <label htmlFor="steps-goal">Steps goal</label>
          <p>* Your daily steps goal</p>
          <input
            type="number"
            name="steps"
            placeholder="Enter your steps goal"
            onChange={handleInputChange}
          />
        </div>
      </form>
      <button
        type="submit"
        form="goals-form"
        className={styles.goalsSaveButton}
      >
        Save
      </button>
      <div>
        {error && <p className={styles.errorGoalsMessage}>* {error}</p>}
      </div>
    </section>
  );
};
