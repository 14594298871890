import { FormEventHandler, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "./AICoachChat.module.css";
import { StoredState } from "../../../lib/storedState";

export type MessageList = {
  id: number;
  conversation_uuid: string;
  message_order: number;
  message_role: string;
  message_text: string;
};

export const AICoachChat = () => {
  const parsedState: StoredState | null = JSON.parse(
    localStorage.getItem("storedState") ?? "null",
  );

  const [currentUuid, setCurrentUuid] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<MessageList[]>([]);

  useEffect(() => {
    const uuid = uuidv4();
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
    headers.append("Content-Type", "application/json");

    const apiEndpoint = new URL(
      "/conversations",
      process.env.REACT_APP_API_BASE_URL,
    );
    const createConversation = async () => {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: JSON.stringify({ uuid: uuid }),
        headers,
      });
      const responseToJson = await response.json();
      setCurrentUuid(responseToJson.uuid);
    };
    createConversation();
  }, []);

  const handleSubmit: FormEventHandler = async (event) => {
    let generatedId = 0;
    event.preventDefault();
    const messageCopy = message;
    setMessage("");
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        id: generatedId++,
        conversation_uuid: currentUuid,
        message_order: 1,
        message_role: "user",
        message_text: messageCopy,
      },
    ]);

    setLoading(true);
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${parsedState?.jwt}`);
    headers.append("Content-Type", "application/json");

    const apiEndpoint = new URL(
      `/conversations/${currentUuid}`,
      process.env.REACT_APP_API_BASE_URL,
    );
    const response = await fetch(apiEndpoint, {
      method: "POST",
      body: JSON.stringify({ message: messageCopy }),
      headers,
    });
    const responseToJson = await response.json();
    setLoading(false);

    if (messages.length === 0) {
      setMessages(responseToJson.messages);
    } else {
      setMessages((prevState) => [
        ...prevState,
        responseToJson.messages[responseToJson.messages.length - 1],
      ]);
    }
  };

  return (
    <section className={styles.chatbotContainer}>
      <div className={styles.responseContainer}>
        <div className={styles.messagesList}>
          {messages.length === 0 && (
            <p>Start messaging our Coach360 Assistant</p>
          )}

          {messages?.map((message) => (
            <div
              className={
                message.message_role === "assistant"
                  ? styles.messageContainerLeft
                  : styles.messageContainerRight
              }
              key={message.id}
            >
              {message.message_role === "assistant" && (
                <div className={styles.messageContainer}>
                  <p>{message.message_text}</p>
                </div>
              )}
              {message.message_role === "user" && (
                <div className={styles.messageContainer}>
                  <p>{message.message_text}</p>
                </div>
              )}
            </div>
          ))}
          {loading && (
            <div className={styles.messageContainerLeft}>
              <div className={styles.messageContainerLoader}>
                <span className={styles.loader1}></span>
                <span className={styles.loader2}></span>
                <span className={styles.loader3}></span>
              </div>
            </div>
          )}
        </div>
        <form className={styles.keyboardContainer} onSubmit={handleSubmit}>
          <textarea
            value={message}
            onChange={(event) => setMessage(event.target.value)}
          ></textarea>
          <button type="submit" className={styles.buttonMessage}>
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </form>
      </div>
    </section>
  );
};
