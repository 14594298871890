import { FormEventHandler, MouseEventHandler, useState } from "react";
import { z } from "zod";
import { NutritionEntryTime } from "../NutritionEntryTime";
import styles from "./NutritionEntry.module.css";
import Logo from "../../../assets/logo-color.png";
import { Link } from "react-router-dom";
import { useNutrition } from "../../../hooks/useNutrition";

const foodData = [
  {
    name: "Fish",
    icon: "fa-solid fa-fish-fins",
  },
  {
    name: "Bread",
    icon: "fa-solid fa-bread-slice",
  },
  {
    name: "Chicken",
    icon: "fa-solid fa-drumstick-bite",
  },
  {
    name: "Egg",
    icon: "fa-solid fa-egg",
  },
  {
    name: "Apple",
    icon: "fa-solid fa-apple-whole",
  },
  {
    name: "Shrimp",
    icon: "fa-solid fa-shrimp",
  },
];

const foodTime = ["Breakfast", "Lunch", "Dinner"];
const foodNameBreakfast = [
  {
    name: "Cereal",
    amount: ["0", "3 g", "5 g", "10 g", "20 g", "25 g", "30 g"],
  },
  {
    name: "Granola",
    amount: ["0", "10 g", "5 g", "3 g", "20 g", "25 g", "30 g"],
  },
  { name: "Bread", amount: ["0", "1", "2", "3", "4", "5", "6", "7", "8"] },
  { name: "Egg", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Oatmeal", amount: ["0", "1 c", "1.5 c", "2 c"] },
  { name: "Sandwich", amount: ["0", "1", "2", "3", "4"] },
  {
    name: "Tea without sugar",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
  {
    name: "Black coffee",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
  {
    name: "Coffee with milk",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
  {
    name: "Orange juice",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
  {
    name: "Lemon juice",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
  { name: "Milk", amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"] },
  { name: "Tomato", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Lettuce", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Cucumber", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Onions", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Banana", amount: ["0", "1", "2", "3", "4", "5"] },
];

const foodNameLunch = [
  {
    name: "Granola",
    amount: ["0", "3 g", "5 g", "10 g", "20 g", "25 g", "30 g"],
  },
  { name: "Bread", amount: ["0", "1", "2", "3", "4", "5", "6", "7", "8"] },
  {
    name: "Cesar Salad",
    amount: ["0", "80 g", "90 g", "100 g", "110 g", "120 g"],
  },
  {
    name: "Spaghetti",
    amount: [
      "0",
      "10 g",
      "20 g",
      "30 g",
      "40 g",
      "50 g",
      "60 g",
      "70 g",
      "80 g",
      "90 g",
    ],
  },
  { name: "Rice", amount: ["0", "1 c", "1,25 c", "1,5 c"] },
  {
    name: "Chicken",
    amount: [
      "0",
      "2 oz",
      "2.5 oz",
      "3 oz",
      "3.5 oz",
      "4 oz",
      "4.5 oz",
      "5 oz",
      "5.5 oz",
      "6 oz",
    ],
  },
  { name: "Meat", amount: ["0", "1 lb", "1.5 lb", "2 lb", "2.5 lb", "3 lb"] },
  { name: "Egg", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Oatmeal", amount: ["0", "1 c", "1.5 c", "2 c"] },
  { name: "Sandwich", amount: ["0", "1", "2", "3", "4"] },
  { name: "Fried eggs", amount: ["0", "1", "2", "3", "4"] },
  {
    name: "Orange juice",
    amount: [
      "0",
      "1 oz",
      "2 oz",
      "3 oz",
      "4 oz",
      "5 oz",
      "6 oz",
      "7 oz",
      "8 oz",
      "9 oz",
    ],
  },
  {
    name: "Lemon juice",
    amount: [
      "0",
      "1 oz",
      "2 oz",
      "3 oz",
      "4 oz",
      "5 oz",
      "6 oz",
      "7 oz",
      "8 oz",
      "9 oz",
    ],
  },
  {
    name: "Milk",
    amount: [
      "0",
      "1 oz",
      "2 oz",
      "3 oz",
      "4 oz",
      "5 oz",
      "6 oz",
      "7 oz",
      "8 oz",
      "9 oz",
    ],
  },
];

const foodNameDinner = [
  {
    name: "Granola",
    amount: ["0", "3 g", "5 g", "10 g", "20 g", "25 g", "30 g"],
  },
  { name: "Bread", amount: ["0", "1", "2", "3", "4", "5", "6", "7", "8"] },
  { name: "Egg", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Oatmeal", amount: ["0", "1 c", "1.5 c", "2 c"] },
  { name: "Sandwich", amount: ["0", "1", "2", "3", "4"] },
  { name: "Tomato", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Lettuce", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Cucumber", amount: ["0", "1", "2", "3", "4", "5"] },
  { name: "Cucumber", amount: ["0", "1", "2", "3", "4", "5"] },
];

const foodNameSnack = [
  { name: "Oat cookie", amount: ["0", "3 g", "5 g", "10 g"] },
  { name: "Granola bar", amount: ["0", "3 g", "5 g", "10 g"] },
  {
    name: "Protein shake",
    amount: ["0", "5 oz", "7 oz", "12 oz", "14 oz", "16 oz"],
  },
];

const ZNutrition = z.object({
  foodTime: z.string().min(1),
  name: z.string().min(1),
  type: z.string().min(1),
  portionSize: z.enum(["Scoop", "Grams", "Ounces", "Liters"]),
  liquid: z.enum(["Water", "Coffee", "Protein shake", "Juice"]),
});

export const NutritionEntry = () => {
  const [selectTime, setSelectTime] = useState<string>("");
  const nutritionInformation = useNutrition();

  const handleFieldChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }

    setSelectTime(value);
  };

  const resetTime: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();
    setSelectTime("");
  };

  return (
    <section className={styles.nutritionEntryContainer}>
      <div className={styles.logoContainer}>
        <figure className={styles.nutritionEntryLogo}>
          <img src={Logo} alt="HealthLink360 logo" />
        </figure>
        {selectTime && (
          <a onClick={resetTime} className={styles.backButton}>
            <i className="fa-solid fa-chevron-left"></i>
          </a>
        )}
        {!selectTime && (
          <Link to="/nutrition/nutrition-home" className={styles.backButton}>
            <i className="fa-solid fa-chevron-left"></i>
          </Link>
        )}
      </div>
      {!selectTime && (
        <div className={styles.nutritionEntrySelectContainer}>
          <label>Click on the time you want to add your meal description</label>
          <div className={styles.nutritionEntryButtons}>
            {foodTime.map((food) => (
              <button onClick={handleFieldChange} data-value={food} key={food}>
                {food}
              </button>
            ))}
          </div>
        </div>
      )}
      {selectTime === "Breakfast" && (
        <NutritionEntryTime
          foodTime={"Breakfast"}
          foodDescription={foodNameBreakfast}
        />
      )}
      {selectTime === "Lunch" && (
        <NutritionEntryTime
          foodTime={"Lunch"}
          foodDescription={foodNameLunch}
        />
      )}
      {selectTime === "Dinner" && (
        <NutritionEntryTime
          foodTime={"Dinner"}
          foodDescription={foodNameDinner}
        />
      )}
    </section>
  );
};
